<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Joins NVIDIA Inception to Develop AI Models for Drug and Target Discovery
      </div>
      <div class="time">
        Mar 12, 2024
      </div>
      <div class="content">
        
        <p>
          CAMBRIDGE, Mass., March 12, 2024 /PRNewswire/ -- GV20 Therapeutics, a clinical stage biotechnology company integrating AI, genomics, and disease biology to create next-generation antibody therapeutics for cancer, announced today that it has joined <a style="color:#2d8cf0;" href="https://www.nvidia.com/en-us/startups/">NVIDIA Inception</a>, a program that nurtures startups revolutionizing industries with technological advancements.
        </p>
        <p>
          GV20 Therapeutics will leverage the resources provided by NVIDIA Inception to develop new AI models for drug discovery, focused on novel therapeutic antibodies. The company will use the <a style="color:#2d8cf0;" href="https://www.nvidia.com/en-us/clara/bionemo/">NVIDIA BioNeMo</a> cloud service to continue scaling and refining the computational arm of the STEAD platform for accelerated target and antibody discovery, which also includes research utilizing genomics and molecular dynamics simulation. The incorporation of NVIDIA BioNeMo into the existing STEAD platform will expedite progress and new innovations to the platform.
        </p>
        <p>
          "We are thrilled to be part of NVIDIA Inception," said Shirley Liu, CEO of GV20 Therapeutics. "We expect the resources provided by the program to help enable us to accelerate the improvement of our STEAD discovery platform and to continue identifying exciting novel cancer drug targets, like IGSF8, and therapeutic antibodies."
        </p>
        <p>
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          At GV20 Therapeutics, our mission is to develop a differentiated pipeline of innovative antibody therapeutics to transform the treatment of cancer. We leverage our in-house STEAD platform (Simultaneous Target Evaluation and Antibody Discovery), which integrates AI, genomics, and disease biology to identify novel drug targets and create next-generation therapies against these targets. Our lead program, GV20-0251, is in Phase 1 for the treatment of solid tumors targeting IGSF8, a promising target for cancer immunotherapy. GV20 is headquartered in Cambridge, MA.
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://gv20tx.com/">https://gv20tx.com/</a> and follow the company on LinkedIn.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>

      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
